import * as React from "react";
import Container from "@mui/material/Container";
import LandingPage from "./LandingPage";
import { Helmet } from "react-helmet";

export default function App() {
  return (
    <>
      <Helmet>
        <title>
          YahWeh Innovations | Custom Website Design & AI Automation
        </title>
        <meta
          name="description"
          content="Providing small business tech solutions, custom website design, AI automation, brand identity services, and more to optimize your business growth."
        />
        <meta
          name="keywords"
          content="Small Business Tech Solutions, Custom Website Design, AI Automation for Businesses, Brand Identity Services, Local SEO Services, Content Marketing Strategies, Customer Journey Optimization, Lead Generation Tactics, Social Media Strategy, Market Analysis, AI and ML Solutions, Content Creation Services"
        />
        <meta name="author" content="Yahweh Innovations" />
      </Helmet>
      <Container
        maxWidth="100vw"
        disableGutters
        style={{ overflowX: "hidden" }}
      >
        <LandingPage />
      </Container>
    </>
  );
}
