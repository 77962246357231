import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, styled } from "@mui/system";

const BulletedListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  "&::before": {
    content: '"•"',
    color: theme.palette.text.primary,
    fontWeight: "bold",
    display: "inline-block",
    width: "1em",
    marginTop: "0em", // Adjust as needed for vertical alignment
    marginRight: "0.5em", // Adjust as needed for horizontal spacing
    fontSize: "1.5em",
    lineHeight: 1, // Ensure consistent line height
  },
  "& .MuiListItemText-root": {
    margin: 0,
  },
}));

const TermsPopup = ({ open, onClose }) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: "90%",
          maxHeight: "90%",
          margin: "auto",
          padding: 2,
          backgroundColor: "hsla(220, 0%, 0%, 0.4)",
          backdropFilter: "blur(16px)",
          backgroundImage: "none",
        },
      }}
      BackdropProps={{
        sx: {
          backdropFilter: "blur(16px)",
          backgroundColor: "rgba(255, 255, 255, 0)",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        Terms of Service
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8, color: "white" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            width: "100%",
            height: "2px",
            backgroundColor: "#2AB5F6",
            margin: "10px 0",
          }}
        />
        <Typography variant="h6" gutterBottom>
          Introduction
        </Typography>
        <Typography paragraph>
          Welcome to YahWeh Innovations Private Limited. These Terms &
          Conditions govern your use of our services and website. By accessing
          or using our services, you agree to be bound by these terms.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Company Information
        </Typography>
        <List>
          <BulletedListItem>
            <ListItemText primary="Name: YahWeh Innovations Private Limited" />
          </BulletedListItem>
          <BulletedListItem>
            <ListItemText primary="Address: Faridabad, Haryana" />
          </BulletedListItem>
          <BulletedListItem>
            <ListItemText primary="Contact: operations@yahweh.co.in, +91-8595412212" />
          </BulletedListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          Services Offered
        </Typography>
        <List>
          <BulletedListItem>
            <ListItemText primary="Technology Solutions: Custom Software Development, Website Development, Database Management, IT Support and Consulting." />
          </BulletedListItem>
          <BulletedListItem>
            <ListItemText primary="Design: Graphic Design, UI/UX Design, Motion Graphics." />
          </BulletedListItem>
          <BulletedListItem>
            <ListItemText primary="Branding: Brand Strategy, Visual Identity, Brand Experience." />
          </BulletedListItem>
          <BulletedListItem>
            <ListItemText primary="Marketing: Digital Marketing, Content Marketing, Email Marketing, Market Research." />
          </BulletedListItem>
          <BulletedListItem>
            <ListItemText primary="Sales: Customer Journey Mapping, Lead Nurturing, Follow-up Strategies." />
          </BulletedListItem>
          <BulletedListItem>
            <ListItemText primary="Event Planning: Corporate Events, Private Events, Event Marketing." />
          </BulletedListItem>
          <BulletedListItem>
            <ListItemText primary="Additional Services: Business Consulting, Recruitment and Staffing, Project Management." />
          </BulletedListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          User Responsibilities
        </Typography>
        <List>
          <BulletedListItem>
            <ListItemText primary="You agree to use our services only for lawful purposes." />
          </BulletedListItem>
          <BulletedListItem>
            <ListItemText primary="You are responsible for maintaining the confidentiality of your account information." />
          </BulletedListItem>
          <BulletedListItem>
            <ListItemText primary="You agree to provide accurate and complete information when creating an account or using our services." />
          </BulletedListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          Payment Terms
        </Typography>
        <List>
          <BulletedListItem>
            <ListItemText primary="Payment terms are specific to the project and will be communicated to each client separately." />
          </BulletedListItem>
          <BulletedListItem>
            <ListItemText primary="All fees are non-refundable unless otherwise agreed upon." />
          </BulletedListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          Confidentiality
        </Typography>
        <Typography paragraph>
          We respect your privacy and confidentiality. All business information
          specific to clients, including sensitive information, will be
          protected.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Intellectual Property
        </Typography>
        <Typography paragraph>
          All content, including text, graphics, logos, and software, is the
          property of YahWeh Innovations Private Limited or its licensors and is
          protected by intellectual property laws.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Termination
        </Typography>
        <Typography paragraph>
          We reserve the right to terminate or suspend access to our services at
          any time, without prior notice, for conduct that we believe violates
          these terms & conditions.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Limitation of Liability
        </Typography>
        <Typography paragraph>
          YahWeh Innovations Private Limited shall not be liable for any
          indirect, incidental, special, consequential, or punitive damages
          arising out of or related to your use of our services.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Governing Law
        </Typography>
        <Typography paragraph>
          These terms & conditions are governed by the laws of India. Any
          disputes arising out of or in connection with these terms shall be
          subject to the exclusive jurisdiction of the courts of Faridabad,
          Haryana.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Changes to Terms
        </Typography>
        <Typography paragraph>
          We reserve the right to modify these terms & conditions at any time.
          Any changes will be effective immediately upon posting on our website.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Effective Date
        </Typography>
        <Typography paragraph>
          These terms and conditions are effective as of 30th June 2024.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default TermsPopup;
