import { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Container,
  MenuItem,
  Drawer,
  CardMedia,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import StyledServicePopover from "./StyledPopover/StyledServicesPopover";
import { NavBarServicesLink } from "../Data/Content/NavBarServicesLink";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function AppNavBar({ mode, onOpenDialog }) {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
      setOpen(false);
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        backgroundImage: "none",
        mt: 2,
      }}
    >
      <Container maxWidth="lg">
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexShrink: 0,
            borderRadius: "10px",
            backdropFilter: "blur(16px)",
            maxHeight: 40,
            border: "1px solid",
            borderColor: "divider",
            bgcolor: "hsla(220, 0%, 0%, 0.4)",
            boxShadow:
              "0 1px 2px hsla(210, 0%, 0%, 0.5), 0 2px 12px hsla(210, 100%, 25%, 0.3)",
          })}
        >
          <Box
            sx={{ flexGrow: 1, display: "flex", alignItems: "center", px: 0 }}
          >
            <CardMedia
              sx={{ width: 120, mr: 2 }}
              component="img"
              image="https://yahweh-landing.s3.ap-south-1.amazonaws.com/nvlogo.png"
              alt="Logo"
            ></CardMedia>
          </Box>

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: 0.5,
              alignItems: "center",
            }}
          >
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Button
                variant="text"
                size="medium"
                onClick={() => scrollToSection("hero")}
                sx={{
                  color: "white",
                  marginX: "10px",
                }}
              >
                Home
              </Button>

              <Button
                variant="text"
                size="medium"
                sx={{
                  marginX: "10px",
                  minWidth: "0px",
                  color: "white",
                }}
              >
                <StyledServicePopover />
              </Button>

              <Button
                variant="text"
                size="medium"
                onClick={() => scrollToSection("faqTiles")}
                sx={{
                  marginX: "10px",
                  color: "white",
                  textTransform: "none",
                }}
              >
                FAQs
              </Button>
              <Button
                variant="text"
                color="info"
                size="medium"
                onClick={onOpenDialog}
                sx={{
                  marginLeft: "10px",
                  color: "white",
                }}
              >
                Careers
              </Button>
            </Box>
          </Box>

          <Box sx={{ display: { sm: "flex", md: "none" } }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                  p: 2,
                  backgroundColor: "background.default",
                  width: "250px",
                  height: "100vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Box>
                    <MenuItem onClick={() => scrollToSection("hero")}>
                      <Typography variant="h6">Home</Typography>
                    </MenuItem>

                    <Accordion
                      sx={{ backgroundColor: "#121212", boxShadow: "none" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{ backgroundColor: "#121212" }}
                      >
                        <Typography variant="h6">Services</Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ backgroundColor: "#121212", boxShadow: "none" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "start",
                            minWidth: "170px",
                            backgroundColor: "#121212",
                          }}
                        >
                          {NavBarServicesLink?.map((value) => (
                            <Button
                              onClick={() => scrollToSection(value.link)}
                              sx={{
                                width: "100%",
                                paddingX: "15px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "start",
                                backgroundColor: "#121212",
                                color: "white",
                              }}
                              key={value.id}
                            >
                              {value.name}
                            </Button>
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>

                    <MenuItem onClick={() => scrollToSection("faqTiles")}>
                      <Typography variant="h6">FAQs</Typography>
                    </MenuItem>
                    <MenuItem onClick={onOpenDialog}>
                      <Typography variant="h6">Careers</Typography>
                    </MenuItem>
                  </Box>
                </Box>
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default AppNavBar;
