import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PopupState, { bindPopover, bindHover } from "material-ui-popup-state";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomPopover = styled(HoverPopover)({
  "& .MuiPaper-root": {
    backgroundColor: "rgba(0, 0, 0, 0.4) !important",
    boxShadow: "none !important",
    color: "white",
    backgroundImage: "none",
    backdropFilter: "blur(16px)",
  },
});

export default function StyledServicesPopover() {
  const tabLink = [
    {
      id: 1,
      name: "Tech",
      link: "softwareService",
    },
    {
      id: 2,
      name: "Design",
      link: "designing",
    },
    {
      id: 3,
      name: "Branding",
      link: "branding",
    },
    {
      id: 4,
      name: "Marketing",
      link: "marketing",
    },
    {
      id: 5,
      name: "Sales",
      link: "sales",
    },
    {
      id: 6,
      name: "Event Planning",
      link: "eventPlanning",
    },
    {
      id: 7,
      name: "Additional Services",
      link: "additionalServices",
    },
  ];
  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
    }
  };
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <Box
            color="info"
            sx={{
              display: "flex",
              paddingX: "0px",
              marginX: "0px",
              height: "100%",
              justifyContent: "center",
              "&:hover": {
                backgroundColor: "inherit",
              },
              color: "white",
            }}
            {...bindHover(popupState)}
          >
            Services{" "}
            <KeyboardArrowDownIcon
              sx={{ paddingX: "0px", minWidth: "0px" }}
            ></KeyboardArrowDownIcon>
          </Box>
          <CustomPopover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "transparent",
              }}
            >
              {tabLink?.map((value) => {
                return (
                  <Button
                    onClick={() => scrollToSection(value.link)}
                    sx={{
                      width: "100%",
                      paddingX: "15px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "start",
                      color: "white",
                      backgroundColor: "transparent",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                      },
                    }}
                    key={value.id}
                  >
                    {value.name}
                  </Button>
                );
              })}
            </Box>
          </CustomPopover>
        </div>
      )}
    </PopupState>
  );
}
