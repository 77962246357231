import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { useSpring, animated } from "react-spring";

const eventPlanningData = [
  {
    title: "Corporate Events",
    short: "Professional Event Solutions",
    description: [
      "Conferences and seminars",
      "Product launches and trade shows",
      "Team-building events and retreats",
    ],
  },
  {
    title: "Private Events",
    short: "Personalized Event Experiences",
    description: [
      "Weddings and celebrations",
      "Birthday parties and anniversaries",
      "Custom-themed events",
    ],
  },
  {
    title: "Event Marketing",
    short: "Strategic Event Promotion Services",
    description: [
      "Event promotion and advertising",
      "Social media campaigns for events",
      "Post-event follow-up and analysis",
    ],
  },
];

export default function EventPlanning() {
  const [hoveredCard, setHoveredCard] = React.useState(null);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const eventPlanningSection = document.getElementById("eventPlanning");
      if (eventPlanningSection) {
        const top = eventPlanningSection.getBoundingClientRect().top;
        setVisible(top < window.innerHeight - 100); // Adjust 100 to a suitable offset
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const springProps = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? "translateX(0)" : "translateX(50px)", // Adjust direction and distance here
  });

  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  return (
    <animated.div style={springProps} id="eventPlanning">
      <Container
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            alignSelf: "start",
            textAlign: "left",
          }}
        >
          <Typography
            gutterBottom
            sx={{ typography: { xs: "h5", sm: "h4" } }}
            component="h1"
          >
            Event Planning{" "}
            <Typography
              sx={{ typography: { xs: "h5", sm: "h4", color: "#2AB5F6" } }}
              variant="h3"
              component="span"
            >
              {" "}
              Services:
            </Typography>{" "}
            <br />
            Memorable{" "}
            <Typography
              sx={{ typography: { xs: "h5", sm: "h4", color: "#2AB5F6" } }}
              variant="h3"
              component="span"
            >
              {" "}
              Experiences
            </Typography>{" "}
          </Typography>
        </Box>
        <Grid
          container
          spacing={3}
          sx={{ alignItems: "center", justifyContent: "center" }}
        >
          {eventPlanningData.map((tier, index) => (
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === "Enterprise" ? 12 : 6}
              md={4}
            >
              <Card
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                  height: "100%",
                  transition: "all 0.3s ease",
                  transform: hoveredCard === index ? "scale(1.1)" : "scale(1)",
                  ...(hoveredCard === index && {
                    border: "none",
                    background:
                      "radial-gradient(circle at 50% 0%, hsl(210, 98%, 35%), hsl(210, 100%, 16%))",
                    boxShadow: `0 8px 12px hsla(210, 98%, 42%, 0.2)`,
                  }),
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      mb: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Typography component="h3" variant="h5">
                      {tier.title}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <Typography component="h3" variant="subtitle1">
                      {tier.short}
                    </Typography>
                  </Box>
                  <Divider
                    sx={{ my: 2, opacity: 0.8, borderColor: "divider" }}
                  />
                  {tier.description.map((line) => (
                    <Box
                      key={line}
                      sx={{
                        py: 1,
                        display: "flex",
                        gap: 1.5,
                        alignItems: "center",
                      }}
                    >
                      <EventNoteIcon
                        sx={{
                          width: 20,
                          color: "#2AB5F6",
                        }}
                      />
                      <Typography
                        variant="subtitle2"
                        component={"span"}
                        sx={{
                          color: null,
                        }}
                      >
                        {line}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </animated.div>
  );
}
