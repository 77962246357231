import { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, Divider } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AppNavBar from "./components/AppNavBar";
import Hero from "./components/Hero";
import SoftwareServiceTiles from "./components/SoftwareServiceTiles";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import TechStack from "./components/TechStack";
import CareersPopup from "./components/CareersPopup";
import ChatDrawer from "./components/ChatDrawer";
import PrivacyPopup from "./components/PrivacyPopup";
import TermsPopup from "./components/TermsPopup";
import Branding from "./components/Branding";
import Marketing from "./components/Marketing";
import Sales from "./components/Sales";
import Designing from "./components/Designing";
import EventPlanning from "./components/EventPlanning";
import AdditionalServices from "./components/AdditionalServices";
import AboutUsPopup from "./components/AboutUsPopup";

export default function LandingPage() {
  const [mode, setMode] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );

  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => {
      const newMode = prev === "dark" ? "light" : "dark";
      localStorage.setItem("selectedTheme", newMode);
      return newMode;
    });
  };

  const [careersDialogOpen, setCareersDialogOpen] = useState(false);
  const [privacyDialogOpen, setPrivacyDialogOpen] = useState(false);
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const [aboutUsDialogOpen, setAboutUsDialogOpen] = useState(false);
  const [ContactDialogOpen, setContactDialogOpen] = useState(false);
  const [data, setData] = useState(0);

  const handleOpenCareersDialog = () => {
    setCareersDialogOpen(true);
  };

  const handleCloseCareersDialog = () => {
    setCareersDialogOpen(false);
  };

  const handleOpenPrivacyDialog = () => {
    setPrivacyDialogOpen(true);
  };

  const handleClosePrivacyDialog = () => {
    setPrivacyDialogOpen(false);
  };

  const handleOpenTermsDialog = () => {
    setTermsDialogOpen(true);
  };

  const handleCloseTermsDialog = () => {
    setTermsDialogOpen(false);
  };
  const handleOpenAboutUsDialog = () => {
    setAboutUsDialogOpen(true);
  };

  const handleCloseAboutUsDialog = () => {
    setAboutUsDialogOpen(false);
  };

  const handleOpenContactDialog = () => {
    setContactDialogOpen(true);
    setData(data + 1); // data is to rerender the ChatDrawer
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppNavBar
        mode={mode}
        toggleColorMode={toggleColorMode}
        onOpenDialog={handleOpenCareersDialog}
      />
      <CareersPopup
        open={careersDialogOpen}
        onClose={handleCloseCareersDialog}
      />
      <PrivacyPopup
        open={privacyDialogOpen}
        onClose={handleClosePrivacyDialog}
      />
      <TermsPopup open={termsDialogOpen} onClose={handleCloseTermsDialog} />
      <AboutUsPopup
        open={aboutUsDialogOpen}
        onClose={handleCloseAboutUsDialog}
      />
      <Hero />
      <Divider />
      <ChatDrawer functionalParams={ContactDialogOpen} />
      <Box sx={{ bgcolor: "background.default" }}>
        <SoftwareServiceTiles />
        <Divider />
        <Designing />
        <Divider />
        <Branding />
        <Divider />
        <Marketing />
        <Divider />
        <Divider />
        <Sales />
        <Divider />
        <EventPlanning />
        <Divider />
        <AdditionalServices />
        <Divider />
        <TechStack mode={mode} />
        <Divider />
        <FAQ />
        <Divider />
        <Footer
          onOpenTerms={handleOpenTermsDialog}
          onOpenPrivacy={handleOpenPrivacyDialog}
          onOpenDialog={handleOpenCareersDialog}
          onOpenContact={handleOpenContactDialog}
          onOpenAboutUs={handleOpenAboutUsDialog}
        />
      </Box>
    </ThemeProvider>
  );
}
