import React, { useEffect, useState } from "react";
import {
  Fab,
  Drawer,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  List,
  ListItem,
  Paper,
  Slide,
  Grid,
  Avatar,
  useMediaQuery,
} from "@mui/material";
import AWS from "aws-sdk";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

const options = [
  "Tech",
  "Design",
  "Branding",
  "Marketing",
  "Sales",
  "Event planning",
  "Others",
];
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const ChatDrawer = (functionalParams) => {
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [problemDescription, setProblemDescription] = useState("");
  const [messages, setMessages] = useState([
    {
      sender: "bot",
      text: "What do you want our help in?",
    },
  ]);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [showTextField, setShowTextField] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);
  const [phoneTouched, setPhoneTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [sendDetailsDisabled, setSendDetailsDisabled] = useState(false);
  const [showFab, setShowFab] = useState(true);
  const [fabOpacity, setFabOpacity] = useState(1);
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    if (isSmScreen) {
      const handleScroll = () => {
        const scrollPosition = window.innerHeight + window.scrollY;
        const threshold = document.documentElement.scrollHeight - 100;

        if (scrollPosition >= threshold) {
          setFabOpacity(0);
        } else {
          setFabOpacity(1);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isSmScreen]);

  useEffect(() => {
    if (fabOpacity === 0) {
      const timeoutId = setTimeout(() => setShowFab(false), 300);
      return () => clearTimeout(timeoutId);
    } else {
      setShowFab(true);
    }
  }, [fabOpacity]);

  useEffect(() => {
    setOpen(functionalParams.functionalParams); // functionalParams is a object, functionalParams.functionalParams is boolean value
  }, [functionalParams]);

  const toggleDrawer = (open) => () => {
    setOpen(open);
    setSelectedOptions([]);
    setProblemDescription("");
    setMessages([
      {
        sender: "bot",
        text: "What do you want our help in?",
      },
    ]);
    setShowTextField(false);
    setShowContactForm(false);
    setPhoneNumber("");
    setPhoneValid(false);
    setEmail("");
    setEmailValid(false);
  };

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOptions((prev) => {
      const updatedOptions = prev.includes(value)
        ? prev.filter((option) => option !== value)
        : [...prev, value];

      setShowTextField(false); // Hide TextField when options change
      setShowContactForm(false); // Hide contact form when options change
      return updatedOptions;
    });
  };

  const handleNextStep = () => {
    if (selectedOptions.length > 0) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "user", text: selectedOptions.join(", ") },
      ]);
      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: "bot",
            text: "Can you describe your problem in detail?",
          },
        ]);
        setTimeout(() => {
          setShowTextField(true);
        }, 500);
      }, 1000);
    }
  };

  const handleSend = () => {
    if (showTextField && problemDescription.trim()) {
      setSendingMessage(true);
      setShowTextField(false); // Hide TextField after sending message
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "user", text: problemDescription },
      ]);
      setTimeout(() => {
        setSendingMessage(false);
        setTimeout(() => {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              sender: "bot",
              text: "Please provide your contact details (Email ID and/or Phone Number).",
            },
          ]);
          setShowContactForm(true); // Show contact form after setting the next bot message
        }, 100);
      }, 1000);
    } else if (showContactForm && (emailValid || phoneValid)) {
      // Handle contact form submission
      setSendingMessage(true); // Simulate sending state
      setTimeout(() => {
        setSendingMessage(false);
        setShowContactForm(false); // Hide contact form after sending details
        try {
          const lambda = new AWS.Lambda();
          const params = {
            FunctionName: "landing-contact-us",
            InvocationType: "RequestResponse",
            Payload: JSON.stringify({
              problemDomain: selectedOptions.join(", "),
              problemDescription: problemDescription,
              email: email,
              mobile: phoneNumber,
            }),
          };
          lambda.invoke(params, (err, data) => {
            if (err) {
              console.error("Error invoking Lambda function:", err);
            } else {
              console.log("Lambda function invoked successfully:", data);
            }
          });
        } catch (err) {
          console.log(err);
        }
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: "user",
            text: `Email: ${email || "No value provided"}\nMobile: ${
              phoneNumber || "No value provided"
            }`,
          },
          {
            sender: "bot",
            text: "Thank you for your response. Our team will soon be in touch with you.",
          },
        ]);
      }, 1000);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value); // Basic email regex validation
    setEmail(value);
    setEmailValid(isValid);
    setEmailTouched(value !== "");
    setSendDetailsDisabled(!(isValid || phoneValid)); // Disable button if both email and phone are invalid
  };

  const handlePhoneChange = (event) => {
    const value = event.target.value;
    const isValid = /^\d{10}$/.test(value); // Basic phone number (10 digits) regex validation
    setPhoneNumber(value);
    setPhoneValid(isValid);
    setPhoneTouched(value !== "");
    setSendDetailsDisabled(!(emailValid || isValid)); // Disable button if both email and phone are invalid
  };

  const getAvatarComponent = (sender) => {
    if (sender === "user") {
      return (
        <Avatar sx={{ bgcolor: "#222", marginLeft: "8px", color: "white" }}>
          <AccountCircleOutlinedIcon />
        </Avatar>
      );
    } else {
      return (
        <Avatar sx={{ bgcolor: "#222", marginRight: "8px", color: "white" }}>
          <SmartToyOutlinedIcon />
        </Avatar>
      );
    }
  };

  return (
    <>
      {showFab && (
        <Fab
          color="primary"
          sx={{
            position: "fixed",
            bottom: 32,
            right: 32,
            backgroundColor: "black",
            "&:hover": { backgroundColor: "#333" },
            minWidth: 120,
            height: 56,
            borderRadius: "28px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            gap: 1,
            textTransform: "none",
            padding: "8px",
            whiteSpace: "nowrap",
            opacity: fabOpacity,
            transition: "opacity 0.3s ease-in-out",
          }}
          onClick={toggleDrawer(true)}
        >
          <ContactPhoneOutlinedIcon sx={{ color: "#2bb4f6" }} />
          <Typography
            variant="body2"
            sx={{
              color: "white",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            Contact Us
          </Typography>
        </Fab>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            background: "linear-gradient(to bottom, #090909, #222222)",
            color: "white",
            width: 350,
          },
        }}
      >
        <Box
          sx={{
            padding: 1,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textAlign: "center",
              borderBottom: "2px solid #0b93f6",
              pb: 1,
              mb: 2,
            }}
          >
            Contact Us
          </Typography>
          <List sx={{ flexGrow: 1 }}>
            {messages.map((message, index) => (
              <Slide
                direction="up"
                in={true}
                mountOnEnter
                unmountOnExit
                key={index}
                timeout={500}
              >
                <ListItem
                  sx={{
                    justifyContent:
                      message.sender === "user" ? "flex-end" : "flex-start",
                  }}
                >
                  {message.sender === "user" ? (
                    <>
                      <Paper
                        sx={{
                          p: 1,
                          maxWidth: "calc(100% - 80px)",
                          backgroundColor: "#e5e5ea",
                          color: "black",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "0.8rem",
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          hyphens: "auto",
                        }}
                      >
                        {message.text}
                      </Paper>
                      {getAvatarComponent(message.sender)}
                    </>
                  ) : (
                    <>
                      {getAvatarComponent(message.sender)}
                      <Paper
                        sx={{
                          p: 1,
                          maxWidth: "calc(100% - 80px)",
                          backgroundColor: "#e5e5ea",
                          color: "black",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "0.8rem",
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          hyphens: "auto",
                        }}
                      >
                        {message.text}
                      </Paper>
                    </>
                  )}
                </ListItem>
              </Slide>
            ))}
            {showTextField && (
              <Slide
                direction="up"
                in={true}
                mountOnEnter
                unmountOnExit
                timeout={500}
              >
                <ListItem
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "20%",
                  }}
                >
                  <TextField
                    fullWidth
                    multiline
                    rowsMax={1}
                    fontSize="0.8rem"
                    variant="outlined"
                    value={problemDescription}
                    onChange={(e) => setProblemDescription(e.target.value)}
                    onKeyPress={handleKeyPress}
                    InputProps={{
                      style: {
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingRight: "8px",
                      },
                      endAdornment: (
                        <Button
                          onClick={handleSend}
                          disabled={sendingMessage}
                          sx={{
                            borderRadius: "50%",
                            minWidth: 16,
                            width: 16,
                            height: 16,
                            backgroundColor: "#2bb4f6",
                            "&:hover": {
                              backgroundColor: "#4dc9f6",
                            },
                          }}
                        >
                          <ArrowUpwardIcon
                            sx={{
                              maxWidth: 16,

                              color: "black",
                            }}
                          />
                        </Button>
                      ),
                      sx: {
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "white",
                          },
                          "&:hover fieldset": {
                            borderColor: "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "white",
                          },
                        },
                        input: { color: "white", paddingRight: "10px" },
                        textarea: { color: "white", fontSize: "0.8rem" },
                      },
                    }}
                  />

                  {getAvatarComponent("user")}
                </ListItem>
              </Slide>
            )}
            {showContactForm && (
              <Slide
                direction="up"
                in={true}
                mountOnEnter
                unmountOnExit
                timeout={500}
              >
                <ListItem
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingLeft: "20%",
                    paddingRight: "8%",
                  }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Email ID"
                    fontSize="0.8rem"
                    value={email}
                    onChange={handleEmailChange}
                    error={emailTouched && !emailValid}
                    helperText={
                      emailTouched && !emailValid && "Invalid email format"
                    }
                    sx={{ mb: 5.3, width: "100%", height: "40px" }}
                    InputProps={{
                      style: {
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        borderColor: "white",
                        color: "white",
                      },
                    }}
                    InputLabelProps={{ style: { color: "white" } }}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Phone Number"
                    fontSize="0.8rem"
                    value={phoneNumber}
                    onChange={(e) => handlePhoneChange(e)}
                    error={phoneTouched && !phoneValid}
                    helperText={
                      phoneTouched &&
                      !phoneValid &&
                      "Invalid phone number format"
                    }
                    sx={{ mb: 3, width: "100%", height: "40px" }}
                    InputProps={{
                      style: {
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        borderColor: "white",
                        color: "white",
                      },
                    }}
                    InputLabelProps={{ style: { color: "white" } }}
                  />
                  <Button
                    variant="contained"
                    onClick={handleSend}
                    sx={{
                      height: "40px",
                      mt: 2,
                      color: "black",
                      float: "right",
                      backgroundColor: "#2bb4f6",
                    }}
                    disabled={sendDetailsDisabled || sendingMessage}
                  >
                    Send Details
                  </Button>
                </ListItem>
              </Slide>
            )}
          </List>
          {messages[messages.length - 1].text ===
            "What do you want our help in?" && (
            <Grid
              container
              spacing={1}
              sx={{ mt: 2, ml: 3, justifyContent: "space-between" }}
            >
              {options.map((option) => (
                <Grid item xs={6} key={option}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedOptions.includes(option)}
                        onChange={handleOptionChange}
                        value={option}
                        sx={{ color: "white" }}
                      />
                    }
                    label={
                      <Typography sx={{ color: "white" }}>{option}</Typography>
                    }
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={handleNextStep}
                  sx={{
                    mt: 2,
                    mr: 4,
                    color: "black",
                    float: "right",
                    backgroundColor: "#2bb4f6",
                  }}
                  disabled={selectedOptions.length === 0} // Disable button if no options are selected
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default ChatDrawer;
