export const FAQContent = [
  {
    panel: "panel1",
    title: "What services does YahWeh Innovations offer?",
    content:
      "YahWeh Innovations provides a comprehensive range of services, including technology solutions for custom software and website development, branding, marketing, sales, content curation and design",
  },
  {
    panel: "panel2",
    title: "How can YahWeh Innovations help my business grow?",
    content:
      "We offer tailored consultation and services addressing your specific business requirements. Our expertise in technology, branding, marketing, sales, content curation and design ensures that you get end-to-end support to achieve your business goals.",
  },
  {
    panel: "panel3",
    title: "What industries do you specialise in?",
    content:
      "We work with a diverse range of industries, including but not limited to tech startups, retail, finance, healthcare, and hospitality. Our versatile team is equipped to handle the unique challenges of different sectors.",
  },
  {
    panel: "panel4",
    title: "Do you offer custom solutions for businesses?",
    content:
      "Yes, we pride ourselves on offering custom solutions that cater to the unique needs of each client. Our team works closely with you to understand your specific requirements and develop a strategy that aligns with your business goals.",
  },
  {
    panel: "panel5",
    title: "How can I get a quote for your services?",
    content: (
      <>
        You can request a quote by filling out the form on our website or
        contacting us directly at{" "}
        <a href="mailto:hello@yahweh.co.in" style={{ color: "#2AB5F6" }}>
          {" "}
          hello@yahweh.co.in
        </a>
        . We'll schedule a consultation to understand your needs and propose a
        tailored solution.
      </>
    ),
  },
  {
    panel: "panel6",
    title: "How do I know which services are right for my business?",
    content:
      "During our initial consultation, we'll assess your business needs and recommend the services that will best help you achieve your goals. Our team is here to guide you every step of the way.",
  },
  {
    panel: "panel7",
    title:
      "What makes YahWeh Innovations different from other consulting firms?",
    content:
      "Our commitment to personalised service, innovative solutions, and client satisfaction sets us apart. We take a holistic approach to consulting, ensuring that all aspects of your business are optimised for success.",
  },
];
