import * as React from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Container,
  IconButton,
  InputLabel,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/X";
import { CardMedia } from "@mui/material";

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: "text.secondary", mt: 1 }}>
      {"All Rights Reserved © "}
      YahWeh &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

const scrollToSection = (sectionId) => {
  const sectionElement = document.getElementById(sectionId);
  const offset = 128;
  if (sectionElement) {
    const targetScroll = sectionElement.offsetTop - offset;
    sectionElement.scrollIntoView({ behavior: "smooth" });
    window.scrollTo({
      top: targetScroll,
      behavior: "smooth",
    });
  }
};
export default function Footer({onOpenTerms, onOpenPrivacy, onOpenDialog, onOpenAboutUs, onOpenContact}) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    if (value === "") {
      setError(false);
      setHelperText("");
    } else if (!validateEmail(value)) {
      setError(true);
      setHelperText("Please enter a valid email address");
    } else {
      setError(false);
      setHelperText("");
    }
  };
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        pt: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "60%" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
            <CardMedia
              sx={{ width: 120, mr: 2 }}
              component="img"
              image="https://yahweh-landing.s3.ap-south-1.amazonaws.com/nvlogo.png"
              alt="Logo"
            ></CardMedia>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ fontWeight: 600, mt: 2 }}
            >
              Join The Newsletter
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary", mb: 2 }}>
              Subscribe for weekly updates. No spams ever!
            </Typography>
            <Stack direction="row" spacing={1} useFlexGap>
              <InputLabel htmlFor="email-newsletter" sx={visuallyHidden}>
                Email
              </InputLabel>
              <TextField
                id="email_newsletter"
                hiddenLabel
                size="small"
                variant="outlined"
                sx={{ width: "100%", height: "40px" }}
                aria-label="Enter your email address"
                placeholder="Your Email Address"
                inputProps={{
                  autoComplete: "off",
                  "aria-label": "Enter your email address",
                }}
                value={email}
                onChange={handleChange}
                error={error}
                helperText={helperText}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  flexShrink: 0,
                  backgroundColor: "#2bb4f6",
                  height: "40px",
                  minWidth: "120px",
                }}
              >
                Subscribe
              </Button>
            </Stack>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            Product
          </Typography>
          <Link color="text.secondary" variant="body2" href="#hero">
            Home
          </Link>
          <Link href="#softwareService" color="text.secondary" variant="body2">
            Tech
          </Link>
          <Link color="text.secondary" variant="body2" href="#branding">
            Branding
          </Link>
          <Link color="text.secondary" variant="body2" href="#marketing">
            Marketing
          </Link>
          <Link color="text.secondary" variant="body2" href="#designing">
            Designing
          </Link>
          <Link color="text.secondary" variant="body2" href="#eventPlanning">
            Event Planning
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            href="#additionalServices"
          >
            Additional Services
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            Company
          </Typography>
          <Link color="text.secondary" variant="body2" component="button" onClick={onOpenAboutUs}
           sx={{ textAlign: "left",}} >
            About Us
          </Link>
          <Link color="text.secondary" variant="body2" component="button"  onClick={onOpenDialog} sx={{
              textAlign: "left",
            }}>
            Careers
          </Link>
          <Link color="text.secondary" variant="body2" href="#faqTiles">
            FAQs
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            Legal
          </Typography>
          <Link
            color="text.secondary"
            variant="body2"
            component="button"
            onClick={onOpenTerms}
            sx={{
              textAlign: "left",
            }}
          >
            Terms
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            component="button"
            onClick={onOpenPrivacy}
            sx={{
              textAlign: "left",
            }}
          >
            Privacy
          </Link>
          <Link color="text.secondary" variant="body2" component="button"  onClick={onOpenContact} sx={{
              textAlign: "left",
            }}>
            Contact
          </Link>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          paddingBottom: "32px",
        }}
      >
        <div>
          <Link
            color="text.secondary"
            variant="body2"
            component="button"
            onClick={onOpenPrivacy}
          >
            Privacy Policy
          </Link>
          <Typography sx={{ display: "inline", mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link
            color="text.secondary"
            variant="body2"
            component="button"
            onClick={onOpenTerms}
          >
            Terms of Service
          </Link>
          <Copyright />
        </div>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{ justifyContent: "left", color: "text.secondary" }}
        >
          <IconButton
            color="inherit"
            target="_blank"
            href="https://www.instagram.com/yahweh_innovations/"
            aria-label="InstagramIcon"
            sx={{ alignSelf: "center" }}
          >
            <InstagramIcon></InstagramIcon>
          </IconButton>
          <IconButton
            color="inherit"
            target="_blank"
            href="https://x.com/embrace_yahweh?s=09"
            aria-label="X"
            sx={{ alignSelf: "center" }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            target="_blank"
            href="https://www.linkedin.com/company/yahweh-innovations/"
            aria-label="LinkedIn"
            sx={{ alignSelf: "center" }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
