import React from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";

const AboutUsPopup = ({ open, onClose }) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: "90%",
          maxHeight: "90%",
          margin: "auto",
          padding: 2,
          backgroundColor: "hsla(220, 0%, 0%, 0.4)", // hsla(220, 0%, 0%, 0.4) in rgba format
          backdropFilter: "blur(16px)", // Heavy blur effect
          backgroundImage: "none",
        },
      }}
      BackdropProps={{
        sx: {
          backdropFilter: "blur(16px)", // Same blur effect for backdrop
          backgroundColor: "rgba(255, 255, 255, 0)", // Adjust backdrop opacity
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        About Us
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8, color: "white" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box
        sx={{
          width: "100%",
          height: "2px",
          backgroundColor: "#2AB5F6",
          margin: "10px 0",
        }}
      />
      <DialogContent
        sx={{
          padding: 10, // Add padding around the content
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          paragraph
          sx={{
            marginBottom: 3, // Adjust the spacing between paragraphs
            lineHeight: 3, // Adjust the line height for better readability
            width: "100%", // Ensure it fits the whole page
            textAlign: "justify", // Ensure text is justified for better appearance
          }}
        >
          We, at YahWeh Innovations, work towards delivering comprehensive
          solutions to elevate your business strategy. Our expertise across
          diverse range of domains like technology, branding, marketing, content
          creation, design and event planning. If you are looking for the
          perfect solution to level up your business approach, book an initial
          consultation with us now. We will analyse your strengths and potential
          and help you achieve your goals.
        </Typography>
        <Typography
          paragraph
          sx={{
            marginBottom: 3, // Adjust the spacing between paragraphs
            lineHeight: 3, // Adjust the line height for better readability
            width: "100%", // Ensure it fits the whole page
            textAlign: "justify", // Ensure text is justified for better appearance
          }}
        >
          At YahWeh, We don't just meet expectations; we exceed them. If you are
          looking for effective guidance for end-to-end technological solutions
          and custom software development, building your brand identity,
          Marketing and Sales, content creation and proofreading, Graphic Design
          and event planning, we have your back. We aim to bring into existence
          whatever can exist and often bring together innovation and creative
          effort to achieve the same.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default AboutUsPopup;
