export const NavBarServicesLink = [
  {
    id: 1,
    name: "Tech",
    link: "softwareService",
  },
  {
    id: 2,
    name: "Design",
    link: "designing",
  },
  {
    id: 3,
    name: "Branding",
    link: "branding",
  },
  {
    id: 4,
    name: "Marketing",
    link: "marketing",
  },
  {
    id: 6,
    name: "Sales",
    link: "sales",
  },
  {
    id: 7,
    name: "Event Planning",
    link: "eventPlanning",
  },
  {
    id: 8,
    name: "Other Services",
    link: "additionalServices",
  },
];
