import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AWS from "aws-sdk";
import { maxHeight, maxWidth, useTheme } from "@mui/system";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const CareersPopup = ({ open, onClose }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [expertise, setExpertise] = useState([]);
  const [experience, setExperience] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [errors, setErrors] = useState({
    firstName: " ",
    lastName: " ",
    email: " ",
    mobileNumber: " ",
    linkedin: " ",
  });
  const [uploading, setUploading] = useState(false); // State to manage upload loader
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const styling = {
    textAlign: isSmallScreen ? "left" : "center",
    width: isSmallScreen ? "96%" : "48%",
    marginRight: isSmallScreen ? "0px" : "4%",
    maxWidth: isSmallScreen ? "90%" : "80%",
    maxHeight: isSmallScreen ? "90%" : "60%",
  };

  const validateField = (field, value) => {
    let validationErrors = {
      firstName: " ",
      lastName: " ",
      email: " ",
      mobileNumber: " ",
      linkedin: " ",
    };

    if (field === "firstName" && !value) {
      validationErrors.firstName = "Please enter your first name";
    }

    if (field === "lastName" && !value) {
      validationErrors.lastName = "Please enter your last name";
    }

    if (field === "email") {
      if (!value) {
        validationErrors.email = "Please enter your email";
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        validationErrors.email = "Please enter a valid email address";
      }
    }

    if (field === "mobileNumber") {
      if (!value) {
        validationErrors.mobileNumber = "Please enter your mobile number";
      } else if (!/^\d{10}$/.test(value)) {
        validationErrors.mobileNumber =
          "Please enter a valid 10-digit mobile number";
      }
    }

    if (field === "linkedin") {
      if (!value) {
        validationErrors.linkedin = "Please enter your LinkedIn profile URL";
      }
    }

    setErrors((prev) => ({ ...prev, ...validationErrors }));
    console.log(errors);
  };

  const handleChange = (field, value) => {
    switch (field) {
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "mobileNumber":
        setMobileNumber(value);
        break;
      case "linkedin":
        setLinkedin(value);
        break;
      default:
        break;
    }
    validateField(field, value);
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setExpertise((prev) => [...prev, value]);
    } else {
      setExpertise((prev) => prev.filter((item) => item !== value));
    }
  };

  const handleSubmit = async () => {
    const validationErrors = {};
    if (!firstName) validationErrors.firstName = "Please enter your first name";
    if (!lastName) validationErrors.lastName = "Please enter your last name";
    if (!email) validationErrors.email = "Please enter your email";
    if (!mobileNumber)
      validationErrors.mobileNumber = "Please enter your mobile number";
    if (!expertise)
      validationErrors.expertise =
        "Please select at least one field of expertise";
    if (!experience)
      validationErrors.experience = "Please select your experience level";
    if (!linkedin)
      validationErrors.linkedin = "Please enter your LinkedIn profile URL";

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      setUploading(true);

      const lambda = new AWS.Lambda();
      const params = {
        FunctionName: "landing-careers",
        InvocationType: "RequestResponse",
        Payload: JSON.stringify({
          firstName,
          lastName,
          email,
          mobileNumber,
          expertise: expertise,
          experience,
          linkedin,
        }),
      };

      const lambdaResponse = await lambda.invoke(params).promise();
      console.log("Lambda function invoked successfully:", lambdaResponse);

      setUploading(false);
      onClose();
    } catch (error) {
      console.error("Error processing:", error);
      setUploading(false);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text/plain");
    const linkedinRegex = /^https:\/\/www\.linkedin\.com\/in\/([\w-]+)\/?$/;

    if (linkedinRegex.test(pastedText)) {
      const profileId = pastedText.match(linkedinRegex)[1];
      const formattedLinkedin = `${profileId}/`;
      setLinkedin(formattedLinkedin);
      setErrors({ ...errors, linkedin: " " });
    } else {
      setErrors({ ...errors, linkedin: "Kindly paste correct LinkedIn URL" });
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: styling["maxWidth"],
          maxHeight: styling["maxHeight"],
          margin: "auto",
          padding: 2,
          backgroundColor: "hsla(220, 0%, 0%, 0.4)",
          backdropFilter: "blur(16px)",
          backgroundImage: "none",
        },
      }}
      BackdropProps={{
        sx: {
          backdropFilter: "blur(16px)",
          backgroundColor: "rgba(255, 255, 255, 0)",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", right: 0, top: 0, color: "white" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant={isSmallScreen ? "subtitle2" : "subtitle1"}
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            textAlign: styling["textAlign"],
          }}
        >
          Embark on your professional journey with our dynamic team, where
          innovation drives every step forward!
        </Typography>
        <Box
          sx={{
            width: "100%",
            height: "2px",
            backgroundColor: "#2AB5F6",
            margin: "10px 0",
          }}
        />
        <Box component="form" sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="First Name"
            value={firstName}
            onChange={(e) => handleChange("firstName", e.target.value)}
            error={errors.firstName && !!errors.firstName.trim()}
            helperText={errors.firstName}
            margin="normal"
            sx={{
              width: styling["width"],
              marginRight: styling["marginRight"],
            }}
          />
          <TextField
            fullWidth
            label="Last Name"
            value={lastName}
            onChange={(e) => handleChange("lastName", e.target.value)}
            error={errors.lastName && !!errors.lastName.trim()}
            helperText={errors.lastName}
            margin="normal"
            sx={{ width: styling["width"] }}
          />
          <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => handleChange("email", e.target.value)}
            error={errors.email && !!errors.email.trim()}
            helperText={errors.email}
            margin="normal"
            sx={{
              width: styling["width"],
              marginRight: styling["marginRight"],
            }}
          />
          <TextField
            fullWidth
            label="Mobile Number"
            value={mobileNumber}
            onChange={(e) => handleChange("mobileNumber", e.target.value)}
            error={errors.mobileNumber && !!errors.mobileNumber.trim()}
            helperText={errors.mobileNumber}
            margin="normal"
            sx={{ width: styling["width"] }}
          />
          <Box sx={{ mt: 2 }}>
            <FormControl
              component="fieldset"
              error={!!errors.expertise}
              margin="normal"
            >
              <FormLabel component="legend" sx={{ fontWeight: "bold" }}>
                What field does your expertise lie in?
              </FormLabel>
              <RadioGroup
                row
                value={expertise}
                onChange={(e) => setExpertise(e.target.value)}
                sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
              >
                {[
                  "Tech",
                  "Designing",
                  "Branding",
                  "Marketing",
                  "Sales",
                  "Event Planning",
                ].map((option) => (
                  <FormControlLabel
                    key={option}
                    value={option}
                    control={<Radio />}
                    label={option}
                    sx={{ width: "100%" }}
                  />
                ))}
              </RadioGroup>
              {errors.expertise && (
                <Typography color="error">{errors.expertise}</Typography>
              )}
            </FormControl>
          </Box>

          <Box sx={{ mt: 2 }}>
            <FormControl
              component="fieldset"
              error={!!errors.experience}
              margin="normal"
            >
              <FormLabel component="legend" sx={{ fontWeight: "bold" }}>
                What experience level jobs are you looking for?
              </FormLabel>
              <RadioGroup
                row
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                {["Internship", "Entry-level job", "Experienced"].map(
                  (option) => (
                    <FormControlLabel
                      key={option}
                      value={option}
                      control={<Radio />}
                      label={option}
                    />
                  )
                )}
              </RadioGroup>
              {errors.experience && (
                <Typography color="error">{errors.experience}</Typography>
              )}
            </FormControl>
          </Box>
          <Box sx={{ mt: 2 }}>
            <FormControl
              component="fieldset"
              error={errors.linkedin && !!errors.linkedin.trim()}
              margin="normal"
            >
              <FormLabel component="legend" sx={{ fontWeight: "bold" }}>
                Enter your LinkedIn profile URL:
              </FormLabel>
              <TextField
                fullWidth
                label="LinkedIn Profile URL"
                value={linkedin}
                onChange={(e) => handleChange("linkedin", e.target.value)}
                onPaste={handlePaste}
                error={errors.linkedin && !!errors.linkedin.trim()}
                helperText={errors.linkedin}
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      https://www.linkedin.com/in/
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    paddingRight: 0, // Removes default padding for cleaner look
                  },
                }}
              />
            </FormControl>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={uploading} // Disable button during upload
              sx={{
                backgroundColor: "#2AB5F6",
                color: "primary",
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CareersPopup;
