import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import ReactIcon from "../Data/SVGIcon/ReactIcon";
import NodejsIcon from "../Data/SVGIcon/NodejsIcon";
import PythonIcon from "../Data/SVGIcon/PythonIcon";
import PhpIcon from "../Data/SVGIcon/PhpIcon";
import MaterialUiIcon from "../Data/SVGIcon/MaterialUiIcon";
import GoLangIcon from "../Data/SVGIcon/GoLangIcon";
import JavaScriptIcon from "../Data/SVGIcon/JavaScriptIcon";
import HtmlIcon from "../Data/SVGIcon/HtmlIcon";
import CSSIcon from "../Data/SVGIcon/CSSIcon";
import { useSpring, animated } from "react-spring";

const TechStack = () => {
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const techStackSection = document.getElementById("techStack");
      if (techStackSection) {
        const top = techStackSection.getBoundingClientRect().top;
        setVisible(top < window.innerHeight - 100); // Adjust 100 to a suitable offset
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const springProps = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? "translateX(0)" : "translateX(50px)",
  });

  let color = "#2bb4f6";

  return (
    <animated.div style={springProps}>
      <Container
        id="techStack"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 4, sm: 12 },
        }}
      >
        <Box textAlign="left">
          <Typography
            gutterBottom
            sx={{ typography: { xs: "h5", sm: "h4" } }}
            component="h1"
          >
            What's Your{" "}
            <Typography
              sx={{ typography: { xs: "h5", sm: "h4", color: "#2AB5F6" } }}
              variant="h3"
              component="span"
            >
              {" "}
              Stack?
            </Typography>{" "}
          </Typography>
          <Typography
            gutterBottom
            sx={{ typography: { xs: "subtitle2", sm: "subtitle1" } }}
          >
            Leverage our software engineers to build your digital products in
            whatever tech stack you need!
          </Typography>
        </Box>
        <Grid container marginTop={"48px"}>
          <Grid
            item
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            xs={6}
            sm={4}
          >
            <Box>
              <ReactIcon color={color}></ReactIcon>
            </Box>
            <Typography variant="h6" sx={{ cursor: "pointer", pb: "16px" }}>
              React
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sm={4}
            flexDirection="column"
          >
            <Box>
              <NodejsIcon color={color}></NodejsIcon>
            </Box>
            <Typography variant="h6" sx={{ cursor: "pointer", pb: "16px" }}>
              Node.js
            </Typography>
          </Grid>

          <Grid
            item
            xs={6}
            sm={4}
            flexDirection="column"
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Box>
              <PythonIcon color={color}></PythonIcon>
            </Box>
            <Typography variant="h6" sx={{ cursor: "pointer", pb: "16px" }}>
              Python
            </Typography>
          </Grid>

          <Grid
            item
            xs={6}
            sm={4}
            flexDirection="column"
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Box>
              <PhpIcon color={color}></PhpIcon>
            </Box>
            <Typography variant="h6" sx={{ cursor: "pointer", pb: "16px" }}>
              PHP
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            flexDirection="column"
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Box>
              <MaterialUiIcon color={color}></MaterialUiIcon>
            </Box>
            <Typography variant="h6" sx={{ cursor: "pointer", pb: "16px" }}>
              Material UI
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            flexDirection="column"
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Box>
              <GoLangIcon color={color}></GoLangIcon>
            </Box>
            <Typography variant="h6" sx={{ cursor: "pointer", pb: "16px" }}>
              Golang
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            flexDirection="column"
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Box>
              <HtmlIcon color={color}></HtmlIcon>
            </Box>
            <Typography variant="h6" sx={{ cursor: "pointer", pb: "16px" }}>
              HTML
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            flexDirection="column"
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Box>
              <CSSIcon color={color}></CSSIcon>
            </Box>
            <Typography variant="h6" sx={{ cursor: "pointer", pb: "16px" }}>
              CSS
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            flexDirection="column"
            display="flex"
            justifyContent="start"
            alignItems="center"
            textAlign="center"
          >
            <Box>
              <JavaScriptIcon color={color}></JavaScriptIcon>
            </Box>
            <Typography variant="h6" sx={{ cursor: "pointer" }}>
              JavaScript
            </Typography>
          </Grid>
        </Grid>

        <Box textAlign="right" sx={{ mt: { xs: 0, sm: 6 } }} mr={"11%"}>
          <Typography
            href="#"
            underline="hover"
            color="#2bb4f6"
            fontWeight={500}
          >
            AND MORE...
          </Typography>
        </Box>
      </Container>
    </animated.div>
  );
};

export default TechStack;
