const HtmlIcon = ({ color }) => {
  return (
    <svg
      width="55px"
      height="55px"
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M5.08057 0H6.1623V1.06875H7.15183V0H8.23361V3.23644H7.15188V2.15269H6.16235V3.23644H5.08061L5.08057 0Z"
          fill={color}
        />
        <path
          d="M9.65622 1.07325H8.7039V0H11.6908V1.07325H10.738V3.23644H9.65626L9.65622 1.07325Z"
          fill={color}
        />
        <path
          d="M12.1648 0H13.2928L13.9866 1.13719L14.6797 0H15.8081V3.23644H14.7308V1.63228L13.9866 2.78302H13.968L13.2232 1.63228V3.23644H12.1648V0Z"
          fill={color}
        />
        <path
          d="M16.3465 0H17.4286V2.16666H18.9498V3.23644H16.3465V0Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.04593 22.0724L3 4.71658H21L18.9527 22.0697L11.9897 24L5.04593 22.0724ZM9.18344 12.5727L8.98891 10.3929H17.1343L17.3249 8.26428H6.6626L7.23672 14.7013L14.6138 14.7013L14.3668 17.4619L11.9927 18.1027L11.991 18.1034L9.62038 17.4633L9.46883 15.7656H7.33203L7.63025 19.1079L11.9906 20.3183L16.3565 19.1078L16.8887 13.1443L16.9406 12.5727L9.18344 12.5727Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default HtmlIcon;
