import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FAQContent } from "../Data/Content/FAQContent";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQAccordionPanel = ({
  panel,
  expanded,
  handleChange,
  title,
  children,
}) => (
  <Accordion
    expanded={expanded === panel}
    onChange={handleChange(panel)}
    disableGutters
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={`${panel}d-content`}
      id={`${panel}d-header`}
    >
      <Typography component="h3" variant="subtitle1">
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography
        variant="subtitle2"
        gutterBottom
        sx={{ maxWidth: { sm: "100%", md: "70%" } }}
      >
        {children}
      </Typography>
    </AccordionDetails>
  </Accordion>
);

const FAQ = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Container
      id="faqTiles"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 4, sm: 12 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        sx={{
          color: "text.primary",
          width: "100%",
          textAlign: "left",
          typography: { xs: "h5", sm: "h4" },
        }}
      >
        Frequently Asked{" "}
        <Typography
          sx={{ typography: { xs: "h5", sm: "h4", color: "#2AB5F6" } }}
          variant="h3"
          component="span"
        >
          {" "}
          Questions
        </Typography>{" "}
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {FAQContent.map(({ panel, title, content }) => (
          <FAQAccordionPanel
            key={panel}
            panel={panel}
            expanded={expanded}
            handleChange={handleChange}
            title={title}
          >
            {content}
          </FAQAccordionPanel>
        ))}
      </Box>
    </Container>
  );
};

export default FAQ;
