import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, styled } from "@mui/system";

const BulletedListItem = styled(ListItem)(({ theme }) => ({
  "&::before": {
    content: '"•"',
    color: theme.palette.text.primary,
    fontWeight: "bold",
    display: "inline-block",
    width: "1em",
    marginLeft: "-1em",
    fontSize: "1.5em",
  },
}));

const PrivacyPopup = ({ open, onClose }) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: "90%",
          maxHeight: "90%",
          margin: "auto",
          padding: 2,
          backgroundColor: "hsla(220, 0%, 0%, 0.4)", // hsla(220, 0%, 0%, 0.4) in rgba format
          backdropFilter: "blur(16px)", // Heavy blur effect
          backgroundImage: "none",
        },
      }}
      BackdropProps={{
        sx: {
          backdropFilter: "blur(16px)", // Same blur effect for backdrop
          backgroundColor: "rgba(255, 255, 255, 0)", // Adjust backdrop opacity
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        Privacy Policy
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8, color: "white" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box
        sx={{
          width: "100%",
          height: "2px",
          backgroundColor: "#2AB5F6",
          margin: "10px 0",
        }}
      />
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Introduction
        </Typography>
        <Typography paragraph>
          YahWeh Innovations Private Limited is committed to protecting your
          privacy. This privacy policy explains how we collect, use, disclose,
          and safeguard your information when you visit our website or use our
          services.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Information We Collect
        </Typography>
        <List>
          <BulletedListItem>
            <ListItemText primary="Personal Information: Name, Email ID, Mobile number, Payment information." />
          </BulletedListItem>
          <BulletedListItem>
            <ListItemText primary="Business Information: Specific to clients, which may include sensitive information." />
          </BulletedListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          How We Use Your Information
        </Typography>
        <List>
          <BulletedListItem>
            <ListItemText primary="To provide and manage our services." />
          </BulletedListItem>
          <BulletedListItem>
            <ListItemText primary="To communicate with you, including sending marketing materials and service updates." />
          </BulletedListItem>
          <BulletedListItem>
            <ListItemText primary="To improve our services based on your feedback and interactions." />
          </BulletedListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          Data Sharing
        </Typography>
        <Typography paragraph>
          We do not share your personal information with third parties unless
          required by law or with your explicit consent.
        </Typography>

        <Typography variant="h6" gutterBottom>
          User Rights
        </Typography>
        <Typography paragraph>
          You have the right to access and correct your personal information. To
          exercise these rights, please contact our team at{" "}
          <a href="mailto:operations@yahweh.co.in" style={{ color: "#2AB5F6" }}>
            {" "}
            operations@yahweh.co.in
          </a>
          .
        </Typography>

        <Typography variant="h6" gutterBottom>
          Cookies
        </Typography>
        <Typography paragraph>
          We use basic cookie functionality provided by browsers to enhance your
          experience on our website. No specific tracking technologies are used.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Data Security
        </Typography>
        <Typography paragraph>
          We employ data encryption to protect your information stored in our
          databases.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Changes to this Privacy Policy
        </Typography>
        <Typography paragraph>
          We may update this privacy policy from time to time. Any changes will
          be posted on our website, and the updated policy will take effect
          immediately upon posting.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Contact Information
        </Typography>
        <Typography paragraph>
          For any questions or concerns about this privacy policy, please
          contact us at{" "}
          <a href="mailto:operations@yahweh.co.in" style={{ color: "#2AB5F6" }}>
            {" "}
            contactus@yahweh.co.in
          </a>
          .
        </Typography>

        <Typography variant="h6" gutterBottom>
          Effective Date
        </Typography>
        <Typography paragraph>
          This privacy policy is effective as of 30th June 2024.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default PrivacyPopup;
